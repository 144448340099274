/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of notification grouping  0 = Undefined  1 = PasswordRelated  2 = Mentioned  100 = BookingConfirmation  101 = QuoteNew  102 = QuoteRefused  103 = QuoteAccepted  104 = QuoteExpired  105 = QuoteReminder  200 = TenderCreated  201 = TenderBidRejected  202 = TenderExpired  203 = TenderReminder  204 = TenderBidReceived  300 = LaneCreated  301 = LanesRequestReminder  400 = OrderAccepting  401 = OrderPodRejection  402 = OrderPodApproval  403 = OrderPodReminder  404 = OrderPickupETA  405 = OrderDeliveryETA  406 = OrderPickupConfirmation  407 = OrderDeliveryConfirmation  408 = OrderTimeslotConfirmation  409 = OrderTransportOrder  410 = OrderCancellation  411 = OrderUpdateReferences  412 = OrderUpdateTemperature  413 = OrderUpdateDriverTruck  414 = OrderUpdateFuelCharge  415 = OrderDriverAssigned  416 = OrderExceptions  1000 = TaskRequestAssignOperator  1001 = TaskRequestOfferMTShipperRate  1003 = TaskManuallyClosed  1501 = AutomationSuccess  1502 = AutomationFailed
 */
export enum NotificationGroupingType {
  Undefined = 0,
  PasswordRelated = 1,
  Mentioned = 2,
  BookingConfirmation = 100,
  QuoteNew = 101,
  QuoteRefused = 102,
  QuoteAccepted = 103,
  QuoteExpired = 104,
  QuoteReminder = 105,
  TenderCreated = 200,
  TenderBidRejected = 201,
  TenderExpired = 202,
  TenderReminder = 203,
  TenderBidReceived = 204,
  LaneCreated = 300,
  LanesRequestReminder = 301,
  OrderAccepting = 400,
  OrderPodRejection = 401,
  OrderPodApproval = 402,
  OrderPodReminder = 403,
  OrderPickupETA = 404,
  OrderDeliveryETA = 405,
  OrderPickupConfirmation = 406,
  OrderDeliveryConfirmation = 407,
  OrderTimeslotConfirmation = 408,
  OrderTransportOrder = 409,
  OrderCancellation = 410,
  OrderUpdateReferences = 411,
  OrderUpdateTemperature = 412,
  OrderUpdateDriverTruck = 413,
  OrderUpdateFuelCharge = 414,
  OrderDriverAssigned = 415,
  OrderExceptions = 416,
  TaskRequestAssignOperator = 1000,
  TaskRequestOfferMTShipperRate = 1001,
  TaskManuallyClosed = 1003,
  AutomationSuccess = 1501,
  AutomationFailed = 1502,
}
