/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = PODRelated  2 = NewTrip  3 = Offered  4 = Update
 */
export enum PushNotificationType {
  Undefined = 0,
  PODRelated = 1,
  NewTrip = 2,
  Offered = 3,
  Update = 4,
}
