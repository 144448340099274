/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Default  1 = DateSpecificMonth  2 = DateToday  3 = DateNextXDays  4 = DateLastXDays  5 = DateTomorrow
 */
export enum FilterValueType {
  Default = 0,
  DateSpecificMonth = 1,
  DateToday = 2,
  DateNextXDays = 3,
  DateLastXDays = 4,
  DateTomorrow = 5,
}
